<script>
import InputField from '@/components/general/InputField'
import HorizontalMenu from '@/components/general/HorizontalMenu'
import ModalSearch from '@/components/discovery/ModalSearch'
import AppliedFilters from '@/components/discovery/AppliedFilters'
import Breadcrumbs from '@/components/general/Breadcrumbs'

export default {
  name: 'Discovery',
  components: {
    InputField,
    ModalSearch,
    AppliedFilters,
    Breadcrumbs,
    HorizontalMenu
  },
  data () {
    return {
      cssParam: '',
      isLoading: true,
      searchingApply: false,
      showSearchModal: false,
      textSearch: '',
      selectedFilter: '0',
      initialSearchParameters: {
        search: null,
        category: null,
        city: null,
        state: null,
        remoteWorkOnly: false,
        contractType: [],
        remuneration: '0',
        undefinedSalary: true,
        exclusivePCD: false,
        institute: null,
        segment: null,
        modality: [],
        workload: null,
        pricing: null
      },
      searchParameters: {
        search: null,
        category: null,
        city: null,
        state: null,
        remoteWorkOnly: false,
        contractType: [],
        remuneration: '0',
        undefinedSalary: true,
        exclusivePCD: false,
        institute: null,
        segment: null,
        modality: [],
        workload: null,
        pricing: null
      },
      selectedMenu: 0
    }
  },
  created () {
    this.selectedFilter = this.$route.query.type === undefined ? '0' : this.$route.query.type === 'positions' ? '0' : '1'
    this.selectedMenu = Number(this.selectedFilter)
  },
  computed: {
    filter () {
      const filter = {}
      Object.keys(this.searchParameters).forEach(searchKey => {
        const searchItem = this.searchParameters[searchKey]

        if (searchKey === 'undefinedSalary' && searchItem) {
          if (this.$route.query.undefinedSalary) {
            filter[searchKey] = this.searchParameters[searchKey]
          }
          return
        }
        if (searchKey === 'undefinedSalary' && !searchItem) {
          filter[searchKey] = this.searchParameters[searchKey]
        }
        if (searchItem && Array.isArray(searchItem) && searchItem.length > 0) {
          filter[searchKey] = this.searchParameters[searchKey]
        } else if (searchItem && searchItem !== '0' && !Array.isArray(searchItem)) {
          filter[searchKey] = this.searchParameters[searchKey]
        }
      })
      return filter
    },
    menuOptions () {
      return [
        { to: { name: this.selectedWorkspace.type + '.discovery.positions.default' }, text: 'global:positions', icon: 'mdi-briefcase' },
        { to: { name: this.selectedWorkspace.type + '.discovery.courses.default' }, text: 'global:courses', icon: 'mdi-school' }
      ]
    },
    isSearching () {
      if (this.searchParameters.search && this.searchParameters.search.length >= 1) {
        this.sendFilter()
        return true
      }
      return Object.keys(this.filter).length > 0
    },
    breadcrumbsPaths () {
      const path = [{ text: 'main.header:discovery', href: this.$route.path }]
      // this.isSearching && path.push({ text: 'global:search', href: this.$route.path })
      return path
    },
    searchResultSample () {
      return this.$store.getters.getFilteredListLength
    }
  },
  methods: {
    changeFilter (filter) {
      this.searchParameters = { ...this.initialSearchParameters, ...filter }
      this.textSearch = this.searchParameters.search
      this.sendFilter()
    },
    changeCategory (filter) {
      this.searchParameters.category = filter
      this.searchParameters.undefinedSalary = true
      this.sendFilter()
    },
    sendFilter () {
      const searchType = this.selectedFilter === '0' ? 'positions' : 'courses'
      if (Object.keys(this.generateQueryFilter()).length === 0) {
        this.$router.push({ name: this.selectedWorkspace.type + `.discovery.${searchType}.default` }).catch(() => {})
      } else {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: searchType, ...this.generateQueryFilter() } }).catch(() => {})
      }
    },
    clearFilter (custom = {}) {
      const searchType = this.selectedFilter === '0' ? 'positions' : 'courses'
      this.searchParameters = { ...this.initialSearchParameters, ...custom }
      this.textSearch = ''
      this.$router.push({ name: this.selectedWorkspace.type + `.discovery.${searchType}.default` }).catch(() => {})
    },
    onClickSearch () {
      this.searchParameters.search = this.textSearch
    },
    generateQueryFilter () {
      const finalFilter = {}
      for (const key in this.searchParameters) {
        if (this.searchParameters[key] !== this.initialSearchParameters[key]) {
          finalFilter[key] = this.searchParameters[key]
        }
      }
      return finalFilter
    },
    switchSelectedFilter (which = '0') {
      this.selectedFilter = which
      this.showAdvancedSearch = false
      setTimeout(() => {
        this.showAdvancedSearch = true
      }, 100)
    }
  },
  watch: {
    '$route.query': {
      handler (search) {
        const filters = { ...this.$route.query }

        if (filters.modality) {
          filters.modality = Array.isArray(filters.modality) ? filters.modality : [filters.modality]
        }
        if (filters.contractType) {
          filters.contractType = Array.isArray(filters.contractType) ? filters.contractType : [filters.contractType]
        }
        delete filters.type
        this.searchParameters = { ...this.initialSearchParameters, ...filters }
        switch (this.$route.params.companyDomain) {
          case 'pwfglobal':
            this.cssParam = 'pwfglobal'
            break
          case 'vivae':
            this.cssParam = 'vivae'
            break
          case 'empregosagro':
            this.cssParam = 'empregosAgro'
            break
          case 'coursera':
            this.cssParam = 'coursera'
            break
          case 'govsp':
            this.cssParam = 'govsp'
            break
          case 'govmt':
            this.cssParam = 'govmt'
            break
          case 'govmg':
            this.cssParam = 'govmg'
            break
          case 'goves':
            this.cssParam = 'goves'
            break
          case 'senacsc':
            this.cssParam = 'senacsc'
            break
          case 'decolaentregadores':
            this.cssParam = 'decolaentregadores'
            break
          case 'acate':
            this.cssParam = 'acate'
            break
          case 'portalfenacon':
            this.cssParam = 'portalfenacon'
            break
          default:
            this.cssParam = ''
            break
        }
      },
      deep: true,
      immediate: true
    },
    selectedMenu () {
      this.selectedFilter = this.selectedMenu.toString()
      this.textSearch = ''
    }
  }
}
</script>
<template>
  <section class="aggregator-discovery--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ $t('discovery.filter:title') }}</h2>
    </div>
    <div class="aggregator-discovery--body">
      <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
      <div class="default-content">
        <p>{{ $t('discovery.filter:description') }}</p>
        <div class="discovery-filter">
          <input-field v-model="textSearch" style="flex-basis: 100%"
            :class="`modal-advanced-search--text-search-${cssParam} mr-2`"
            :placeholder="$t(`discovery.filter:field.search.input.${selectedFilter}`)" outlined buttonHeight
            @keydown.enter="searchParameters.search = textSearch" @click:append="searchParameters.search = textSearch" hideValidation
            append-icon="mdi-magnify" clearable></input-field>

          <v-btn class="btn transform-unset" dark :color="getPrimaryColor" @click="showSearchModal = true"><v-icon
              class="mr-1">mdi-tune</v-icon>{{ $t('global:filter') }}</v-btn>
        </div>
        <modal-search v-model="searchParameters" :show="showSearchModal"
          :filterName="selectedFilter === '0' ? 'positions' : 'courses'" @close="showSearchModal = false"
          :selectedFilter="selectedFilter"></modal-search>
        <applied-filters v-if="!!isSearching" :totalItems="searchResultSample" :filters="filter"
          :queryRoute="selectedFilter === '0' ? 'positions' : 'courses'" @applyFilter="changeFilter" />
      </div>
    </div>
    <router-view />
  </section>
</template>
<style lang="scss">
.aggregator-discovery--container {
  .aggregator-discovery--body {

    p {
      margin-top: 20px;
    }

  }

  .discovery-filter {
    display: flex;
    justify-content: space-between;

    .modal-advanced-search--text-search-empregosAgro {
      margin-bottom: 20px;

      .v-input__slot,
      .v-input__control,
      .v-input {
        height: 40px;
        min-height: 40px;

        &.v-input--is-focused {
          .v-input__append-inner:nth-last-child(1) {
            .v-icon {
              color: $neutral-white !important;
            }
          }
        }
      }

      .v-input__append-inner {
        margin-top: 8px;
        padding-right: 4px;
      }

      .v-input__append-inner:nth-last-child(1) {
        //mudar para variavel global
        background: #006C4C;
        margin: 0 -12px 0 0;
        border-radius: 0 4px 4px 0;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .v-icon {
          color: $neutral-white;
        }
      }
    }

    .modal-advanced-search--text-search-wfglobal {
      margin-bottom: 20px;

      .v-input__slot,
      .v-input__control,
      .v-input {
        height: 40px;
        min-height: 40px;

        &.v-input--is-focused {
          .v-input__append-inner:nth-last-child(1) {
            .v-icon {
              color: $neutral-white !important;
            }
          }
        }
      }

      .v-input__append-inner {
        margin-top: 8px;
        padding-right: 4px;
      }

      .v-input__append-inner:nth-last-child(1) {
        background: #AA2721;
        margin: 0 -12px 0 0;
        border-radius: 0 4px 4px 0;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .v-icon {
          color: $neutral-white;
        }
      }
    }

    .modal-advanced-search--text-search-vivae {
      margin-bottom: 20px;

      .v-input__slot,
      .v-input__control,
      .v-input {
        height: 40px;
        min-height: 40px;

        &.v-input--is-focused {
          .v-input__append-inner:nth-last-child(1) {
            .v-icon {
              color: $neutral-white !important;
            }
          }
        }
      }

      .v-input__append-inner {
        margin-top: 8px;
        padding-right: 4px;
      }

      .v-input__append-inner:nth-last-child(1) {
        background: #72009C;
        margin: 0 -12px 0 0;
        border-radius: 0 4px 4px 0;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .v-icon {
          color: $neutral-white;
        }
      }
    }
  }
}
</style>
